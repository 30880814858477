﻿/* jslint browser: true */
// Glue code which exposes methods recieves messages from the Vault iFrames.
// Switches the vault context like "send"ing to the host context of "receive"ing.

(function ($) {
    //console.log('vaultHostInterface: Loading file.');

    // If this is commented out you will get the error:
    // Uncaught SecurityError: Failed to read the 'frame' property from 'Window': Blocked a frame
    //  with origin "https://vault.db101.org" from accessing a frame with origin 
    //  "https://testbed.db101.org". The frame requesting access set "document.domain" to 
    //  "db101.org", but the frame being accessed did not. Both must set "document.domain" to 
    //  the same value to allow access.
    //document.domain = 'db101.org';
    var shortViews = ['sendEmail'];

    // sticky overlay
    var overlayHeight = {
        db101: 32,
        hb101: 32,
        disabilityhubmn: 172
    };

    // Glue to have host receive messages from the embedded iframe.
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent",
		messageListener = window[eventMethod],
        messageEvent = (eventMethod == "attachEvent") ? "onmessage" : "message";

    // Semaphore to distinguish changes to the URL that come from hostShowHash from those coming from outside the house.
    var suspendHashChange = false;

    // Listen to message from embedded application, who posts messages to me (this), the parent window.
    messageListener(messageEvent, function (event) {
        var key = event.message ? "message" : "data",
		data = event[key];
        handleMessageFromVaultApplication(data);
    }, false);

    // Why attach to global jQuery object in parent window?
    // So parent page can run a jQuery selector and call InstallVault on the
    // resulting object, ie: $('#vaultIframe').installVault();
    $.fn.extend({
        installVaultDefaults: function () {
            return {
                JWT: '',
                backToArticleUrl: '/',
                service: '//vault.db101.org',
                target: '',
                language: 'en',
                stateCode: '',
                panel: false,
                leaveOnLogout: true,
                vaultUri: true,
                title: 'Vault',
                userName: $.fn.logon$getData('UserName'),
                org: JSON.parse($.fn.logon$getData('Organization') || '{}').Key,
                roles: JSON.parse($.fn.logon$getData('Roles')),
                gaClientId: window._gauaid, // stuffed in beginHead
                measurementId: window._gamid
            };
        },
        installVault: function (overrideOptions) {
            var combinedOptions = $.extend({}, $.fn.installVaultDefaults(), overrideOptions);
            // strip out hash part of referrer url
            if (combinedOptions.backToArticleUrl.indexOf('#') > 0)
                combinedOptions.backToArticleUrl = combinedOptions.backToArticleUrl.substring(0, combinedOptions.backToArticleUrl.indexOf('#'));
            //var isOldIE = (navigator.userAgent.indexOf("MSIE") !== -1); // Detect IE10 and below
            var $this = $(this),
                $id = $this.attr('id'),
                $isPanel = combinedOptions.target.indexOf('Panel') > 0,
                uri = combinedOptions.service + combinedOptions.target,
                q = uri.lastIndexOf('?'),
                h = uri.lastIndexOf('#'),
                qExtra = 'iid=' + $id + '&host=' + window.location.hostname + (combinedOptions.domainBase ? '&domainBase=' + combinedOptions.domainBase : '');
            // old-style vault URI processing. May result in mis-ordered hash before querystring.
            if (combinedOptions.vaultUri) {
                uri = uri + (q > 0 ? '&' : '?') + qExtra;
            } else {
                // fix up querystring. It may be buried behind hash.
                if (h < 0 || h < q) {
                    // no hash, or incorrectly formatted with hash first
                    uri = uri + (q > 0 ? '&' : '?') + qExtra;
                } else {
                    // must parse hash and querystring
                    // TODO: handle q < 0
                    var hash = uri.substring(h),
                        query = (q > 0 ? uri.substring(q, h) : ''),
                        stem = uri.substring(0, q > 0 ? q : h);
                    //console.log('uri, hash, query, stem: ' + uri + ', ' + hash + ', ' + query + ', ' + stem);
                    uri = stem + query + (q > 0 ? '&' : '?') + qExtra + hash;
                    //console.log('result: ' + uri);
                }
            }
            combinedOptions.panel = $isPanel;

            var $min = ($isPanel || $.inArray(combinedOptions.target, shortViews) ? '100px' : '500px');
            $this.css({ 'min-height': $min, 'position': 'relative' }).addClass('vaultSpinParent spin');
            $this.append($('<div class="spinner-wrap"><div class="spinner-border"></div></div>'));

            //// on manual logout, navigate home
            //if (!$isPanel && combinedOptions.leaveOnLogout)
            //    $.gevent.subscribe($this, 'efw.logoutmanual', function (e) {
            //        e.stopPropagation();
            //        window.location = "/";
            //    });
            setTimeout(function () { $this.removeClass('spin'); }, 30 * 1000);
            $this.append('<iframe style="width: 100%; border: 0;" id="' + $this.attr('id') + 'Frame' + '" title="' + combinedOptions.title + '" allow="geolocation;camera;microphone"></iframe>');
            $this.find('iframe')[0].vaultParameters = combinedOptions;
            //console.log('setting: ' + uri);
            $this.find('iframe').on('load', function () {
                var $div = $(this).closest('div');
                $(this).iFrameResize({ log: false, sizeHeight: true, minHeight: parseInt($div.css('min-height')), heightCalculationMethod: 'taggedElement' });
            }).attr('src', uri);

            // call this function to adjust frame's vaultParameters when logon state changes.
            var _fixFrameData = function() {
                var $iframes = $this.find('iframe'),
                    $iframe = $iframes.length > 0 ? $iframes[0] : null,
                    o = {
                        userName: $.fn.logon$getData('UserName'),
                        roles: JSON.parse($.fn.logon$getData('Roles')), 
                        org: JSON.parse($.fn.logon$getData('Organization') || '{}').Key,
                        JWT: $.fn.logon$getData('access_token')
                    };

                if ($iframe)
                    $.extend($iframe.vaultParameters, o);
            };

            // give frame a crack at keystrokes, like ESC
            $('body').on('keyup', function (e) {
                callVaultMethod($id, 'keyUp', { keyCode: e.keyCode });
            });
            // give frame a chance to deal with logon/logout
            $.gevent.subscribe($this, 'efw.logon', function () {
                _fixFrameData();
                callVaultMethod($id, 'logon', {});
            });
            $.gevent.subscribe($this, 'efw.logout', function () {
                _fixFrameData();
                callVaultMethod($id, 'logout', {});
            });
            // listen for external changes to the hash.
            if (window.addEventListener) {
                window.addEventListener('hashchange', function (e) {
                    if (suspendHashChange) {
                        //console.info('I know, I know');
                    } else {
                        //console.info(`Hashchange from "${e.oldURL}" to "${e.newURL}"`);
                        hostNavigateTo();   // force reload.
                    }
                });
            }
        }
    });

    function hostNavigateTo(target, tab) {
        console.info(`hostNavigateTo(${target}, ${tab || '""'})`);
        // Need smarts here to determine if this needs an iframe reload,
        // parent window navigate to new page or
        // vault navigate to new page
        // or something else
        if (!target) {
            window.location.reload(false);
            return;
        }

        // identify attempt to navigate to a vault page we're already on
        var looksLike = function (tgt, path) {
            return (tgt.indexOf('/' + path + '#') == 0 && window.location.toString().indexOf(path));
        };

        if (target.indexOf('://') > 0 && target.indexOf(window.location.hostname) < 0) {
            //console.info('...case 1: ' + target);
            window.open(target, '_blank');
        } else if (looksLike('vault.htm') || looksLike('my-vault')) {
            //console.info('...case 2: ' + target.substring(11));
            callVaultMethod('vaultProjects', 'navigateTo', target.substring(11));
        } else if (tab?.length > 0) {
            window.open(target, tab);
        } else {
            //console.info('...case 3: ' + target);
            window.location = target;
        }
    };

    function hostClickOn(selector) {
        // request for the host to click on a selector in its DOM space
        var $target = $(selector);
        if ($target.length > 0) {
            $($target[0]).trigger('click');
        } else {
            console.info('hostClickOn: selector not found: "' + selector + '"');
        }
    };

    // Display the supplied hash on the navigation bar. Won't result in a browser navigation event.
    function hostShowHash(hash) {
        //console.info('suspendHashChange...');
        suspendHashChange = true;
        window.location.hash = hash;
        setTimeout(function () {
            // hold until the next event loop.
            suspendHashChange = false;
            //console.info('...suspendHashChange');
        }, 100);  // like _.defer()
    }

    // Helper function to grab the installVault parameters from iFrame and invoke the
    // generic callVaultMethod function
    function sendHostContextToVault(vaultDivID) {
        // Fetch the parameters that installVault attached to the iFrame dom object.
        // Sent the parameters to vault, this calls global method on iFrame window object,
        // loaded from source file vaultInterface.js
        var data = $('#' + vaultDivID).find('iframe')[0].vaultParameters;
        callVaultMethod(vaultDivID, 'setHostContext', data);
    }

    // Generic vault interface method caller so we don't have to be changing hostInterface anymore
    // in order to extend the vault interface to the parent
    function callVaultMethod(vaultDIVId, methodName, data) {
        var iFrameJQ = $('#' + vaultDIVId).find('iframe')[0];
        if (iFrameJQ && iFrameJQ.contentWindow) {
            iFrameJQ.contentWindow.postMessage({ sender: 'vaultParent', command: methodName, payload: data }, '*');
        }
        //    if (iFrameJQ && iFrameJQ.contentWindow && iFrameJQ.contentWindow[methodName]) {
        //        iFrameJQ.contentWindow[methodName](data);
        //    }
    }

    function handleMessageFromVaultApplication(request) {
        switch (request.command) {
            case 'hostNavigateTo':
                hostNavigateTo(request.target, request.tab);
                break;
            case 'requestHostContext':
                sendHostContextToVault(request.iFrameName);
                break;
            case 'scrollToTop':
                var offset = request.offset || 0,
                    frameTop = $('#' + request.iFrameName).offset().top,
                    totalOffset = offset + frameTop,
                    ifOutOfFrame = !!request.ifOutOfFrame,
                    viewportTop = $(window).scrollTop(),
                    viewportBottom = viewportTop + $(window).height(),
                    doScroll = ifOutOfFrame ? (totalOffset < viewportTop || totalOffset > viewportBottom ? true : false) : true;

                if (doScroll)
                    $('html, body').animate({ scrollTop: totalOffset - $('#family_shell').height() }, 500);
                break;
            case 'closeDialog':
                $('.modal').modal('hide');
                break;
            case 'vaultDoneLoading':
                console.log('vaultDoneLoading!');
                $('.vaultSpinParent').removeClass('spin');
                break;
            case 'requestViewPortInfo':
             {
                // 8/19/2022. Hub has sticky overlay that covers 172 pixels at the top of the screen, distorting Vault dialog placement.
                let overlayBite = 0,
                    host = window.location.hostname;
                Object.keys(overlayHeight).forEach((k) => {
                    if (host.indexOf(k) > 0) {
                        overlayBite = overlayHeight[k];
                    }
                });
                callVaultMethod(request.iFrameName, 'setViewPortInfo', {
                    scrollTop: $(window).scrollTop() + overlayBite,
                    height: $(window).height() - overlayBite,
                    iFrameOffset: $('#' + request.iFrameName).offset()
                });
                break;
            } 
            case 'expireSession':
                $.fn.logon$sessionExpired();
                break;
            case 'requestLogon':
                $('#logon_or_register').modal('show');
                break;
            case 'navigateAndPing':
                hostNavigateTo(request.target);
                if (request.pingSelector)
                    $(request.pingSelector).ping();
                break;
            case 'requestDialogPop':
                // allow caller to override data defaults. Clear immediately when dialog closes.
                $.extend($.fn.logon$sharePageData.share, request.share || {});
                $.extend($.fn.logon$sharePageData.merge, request.merge || {});
                $('#' + request.target).on('dialogclose', function () {
                    $.fn.clearSharePageData();
                }).dialog('open');
                break;
            case 'hostShowHash':
                hostShowHash(request.hash);
                break;
            case 'hostClickOn':
                hostClickOn(request.selector);
                break;
            default:
                // iframe resizer sends lots of messages, can't log error
                // console.log('vaultHostInterface ' + request.command + 'command unknown');
                break;
        }
    }
})(jQuery);

