/* global require */
// depends on: jquery, lodash
// small plugin for uniform form validation

const _ = require('lodash');
(function ($) {
    $.fn.logon$formvalid = function (opts) {

        // Call with 'check' to get form validity
        if ('check' === opts) {
            if (this.length > 0) {
                // clear aria-invalid attributes
                this.find('input').removeAttr('aria-invalid');
                let valid = this[0].checkValidity();
                this.addClass('was-validated');
                return valid;
            }
            return false;
        }

        // without argument: prep the forms
        // this = $(forms)
        return this.each(function () {
            // strategy: Watch change events on form elements. 
            $(this).on('change, keypress, keydown', function (e) {
                _.defer(function () {
                    //console.log('deferred input change/keypress: valid/invalid ' + $(e.target).is(':valid') + '/' + $(e.target).is(':invalid'));
                    $(e.target).attr('aria-invalid', $(e.target).is(':invalid'));
                })
            });

            $(this).find('input').on('invalid', function () {
                //console.log('Invalid event fired: ' + $(this).is(':valid'));
                $(this).attr('aria-invalid', true);
            });
        });
    };
})(jQuery);