/* global require */
// depends-on: Bootstrap mobile
// depends-on: lodash
// TODO: Eliminate dependencies on spin and jQueryUI in favor of Bootstrap components.
(function ($) {
    // Eightfold Way dialog plugin
    const _ = require('lodash');
    const ID = require('./efw.id');

    // $.isValidSelector global function
    jQuery.extend({
        isValidSelector: function (selector) {
            if (typeof (selector) !== 'string') {
                return false;
            }
            try {
                var $element = $(selector);
            } catch (error) {
                return false;
            }
            return true;
        }
    });

    $.fn.efw$dialog = function (options) {
        var opts = $.extend({}, $.fn.efw$dialog.defaults, options);

        return this.each(function () {
            var $dlgControl = $(this);

            if ($dlgControl.is('.initialized')) {
                return; // don't run the plugin twice on any given dialog.
            }

            // reparent dialog to <body> tag
            $('body').append($dlgControl);

            // reconfigure dialog elements
            var $dlg = $dlgControl.find('.modal-dialog'),
                $dlgContent = $dlgControl.find('.modal-content'),
                $dlgHeader = $dlg.find('.modal-header');

            // close box
            var $close = $dlgHeader.find('.close');
            if ($close.length == 0) {
                // create close box from scratch if not provided in the dialog area.
                $close = $('<button type="button" class="close" data-dismiss="modal" aria-label="' + opts.closePrompt + '"><span aria-hidden="true">&times;</span></button>');
                $dlgHeader.append($close);
            }
            // spinner
            $dlgContent.append($('<div class="spinner-wrap"><div class="spinner-border"></div></div>'));

            // form
            if (opts.injectForm) {
                // Empty "type" attribute designed to fix LastPass bug: https://github.com/KillerCodeMonkey/ngx-quill/issues/351
                var $formCode = '<form action="form.frm" method="post" class="needs-validation" novalidate="novalidate" type=""></form>';
                // special form-target div, or wrap everything by default
                if ($dlg.find('form').length === 0) {
                    if ($dlg.find('.form').length > 0) {
                        $dlg.find('.form').each(function (i, e) {
                            $(e).children().wrapAll($formCode);
                        });
                    } else
                        $dlgContent.children().wrapAll($formCode);
                }
            }
            // close-dialog links shortcut
            $dlgControl.find('.dlgClose').click(function (e) {
                e.preventDefault();
                $(this).closest('.modal').modal('hide');
            });
            // enter key simulates submit
            $dlgControl.find('.form-control').keypress(function (event) {
                if (13 == event.which) {
                    // return key in textarea is ok, let it go
                    if ($(event.target).is('textarea')) {
                        return true;
                    }

                    $(this).closest('.modal').find('button[type=submit]:visible').first().focus().click();
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                }
            });

            // spin events
            $dlgControl.on('efw.spin', function () {
                _.debounce(
                    _.bind(function () {
                        $(this).addClass('spin');
                    }, this),
                    500
                );                    
            });

            $dlgControl.on('efw.unspin', function () {
                $(this).removeClass('spin');
            });

            // kill the spinner when dialog changes state. Reset the form.
            $dlgControl.on('show.bs.modal hide.bs.modal', function () {
                $(this).trigger('efw.unspin');
                var $form = $(this).find('form');
                if (!$(this).hasClass('keepData'))
                    $form.trigger('reset').removeClass('was-validated');
                $(this).find('ul.status').empty();  // clear any status info
            });

            // apply accessibility retrofit.
            $dlgControl.efw$dialogAccessabilityRetrofit();

            $dlgControl.addClass('initialized');
            $dlgControl.trigger('dlg.initialized');
        });
    };

    $.fn.efw$dialog.defaults = { formID: '_f8_save_pop', injectForm: false, closePrompt: 'Close' };

    // Small plugin to prep links to dialogs.
    $.fn.efw$dialogPopPrep = function (/*sel*/) {
        // convert to Bootstrap semantics.
        return this.each(function () {
            var $this = $(this),
                href = $this.attr('href');
            // validate the target. Is it in fact pointing to a modal on the page?
            if (!$this.is('.dlgPop') && href.length > 1 && '#' == href.charAt(0) && href.indexOf('/') < 0 && $.isValidSelector(href)) {
                if ($(href).is('.modal')) {
                    $this.addClass('dlgPop');
                }
            }
        });
    };

    // Small plugin for links to dialogs.
    $.fn.efw$dialogPop = function (/*sel*/) {
        // convert to Bootstrap semantics.
        return this.each(function () {
            var $this = $(this);

            // don't modify twice.
            if ($this.data('target'))
                return;

            // special redirect for Estimator feedback.
            if ($('body').is('.pv_BP101_3') && '#comment_dlg' === $this.attr('href') && $('#feedback-bp101').length > 0) {
                $this.attr('href', '#feedback-bp101');
            }
            $this.attr('data-target', $this.attr('href'));
            $this.attr('data-toggle', 'modal');
            $this.prop('href', 'javascript:void(0);');
            $this.on('click', function (e) {
                var target = $this.data('target') + '';
                // validate the argument a little
                if (target && target.length > 1 && '#' == target.charAt(0)) {
                    var $dlg = $(target);
                    $dlg.data('opener', e.delegateTarget);  // who was clicked?
                    // hide any open modal we're in.
                    var $modal = $this.closest('.modal');
                    if ($modal.length > 0) {
                        $modal.modal('hide');
                    }
                }
                // let the Bootstrap handler work.
            });
        });
    };

    // Small plugin for close buttons.
    $.fn.efw$dialogClose = function () {
        return this.each(function () {
            $(this).off('click.efwdialog').on('click.efwdialog', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('.modal').modal('hide');
                // maybe i'm in an iFrame!
                if (window.parent) {
                    window.parent.postMessage({ message: 'efw.close-dialogs' }, window.location.origin)
                }
            });
        });
    };

    $.fn.efw$dialogListener = function () {
        // globally listen for anybody (like an iframe) to tell us to close dialogs.
        const listener = function (e) {
            if (e?.data?.message == 'efw.close-dialogs') {
                console.log('closing all dialogs');
                $('.modal').modal('hide');
            }
        };

        // don't listen twice.
        window.removeEventListener('message', listener, false);
        window.addEventListener('message', listener, false);
    };

    // Small plugin to capture tab navigation within all Bootstrap modals.
    $.fn.efw$dialogAccessabilityRetrofit = function (/*sel*/) {
        return this.each(function () {
            $(this).off('show.bs.modal.efwdialog').on('show.bs.modal.efwdialog', function () {
                // ensure that we have an accessible name
                if (!$(this).attr('aria-labelledby') && !$(this).attr('aria-label')) {
                    // ...find the first header tag
                    var $headers = $(this).find('h1, h2, h3, h4, h5');
                    if ($headers.length > 0) {
                        // first one is the title. Does it have an ID already?
                        var id = $headers.first().attr('id');
                        if (!id) {
                            // generate one. The dialog itself should have an ID.
                            var dlgid = $(this).attr('id');
                            if (!dlgid) {
                                // jeez.
                                id = ID();
                            } else {
                                id = dlgid + '_label';
                            }
                            $headers.first().attr('id', id);
                        }
                        // label the dialog.
                        $(this).attr('aria-labelledby', id);
                    }
                }
            })
                .off('shown.bs.modal.efwdialog').on('shown.bs.modal.efwdialog', function () {
                    // find the list of focusable elements
                    this.focusable = $(this).find('a[href], area[href], input, input:radio, select, textarea, button, [tabindex="0"]').filter(':not(disabled)').filter(':visible');

                    if (this?.focusable?.length > 0) {
                        this.focusable[0].focus();  // often the close button.
                    }

                    if (this.focusable.length > 0) {
                        // capture tab navigation.
                        $(this).off('keydown.efwdialog').on('keydown.efwdialog', function (event) {
                            if (event.which === 9 && document.activeElement === this.focusable[!event.shiftKey ? this.focusable.length - 1 : 0]) {
                                this.focusable[!event.shiftKey ? 0 : this.focusable.length - 1].focus();
                                event.preventDefault();
                            }
                        });
                    }
                })

                .off('hide.bs.modal.efwdialog').on('hide.bs.modal.efwdialog', function () {
                    // remove that tab handler.
                    $(this).off('keydown.efwdialog');
                });
        });
    };

    //console.log('dialog: main');
    $(document).ready(function () {
        //console.log('dialog: subscribe');
        $.gevent.subscribe($('body'), 'init-efw', function (event) {
            //console.log('dialog: called: ' + $(event.target).is('body'));
            // handle this event only when it's called directly on us
            if (event.target !== event.currentTarget)
                return;

            // catch dialog-pops that don't have flag class (allows us to put them in menus)
            $('a[href^="#"]').efw$dialogPopPrep();
            $('a.dlgPop').efw$dialogPop();
            $.fn.efw$dialogListener();
            $('.button-close').efw$dialogClose();
            $('.modal').efw$dialogAccessabilityRetrofit();
            $('.modal').modal({ show: false });
        });
    });

    // activate globally.
    //$(document).ready(function () {
    //    // catch dialog-pops that don't have flag class (allows us to put them in menus)
    //    $('a[href^="#"]').efw$dialogPopPrep();
    //    $('a.dlgPop').efw$dialogPop();
    //    $.fn.efw$dialogListener();
    //    $('.button-close').efw$dialogClose();
    //    $('.modal').efw$dialogAccessabilityRetrofit();
    //});
})(jQuery);
