﻿(function ($) {
    // globals
    var OPTS = {};
    var VALIDATE_KEY = "validate.";
    var FUNCTION_TAG = ".format(";

    var _interpolate = function (s, model) {
        // we're looking for muscache-style syntax, {{field}}
        if (!s || !model || s.indexOf('{{') < 0) {
            return s;
        }

        return s.replace(/\{\{([^\}]+)\}\}/g, function (match, token) {
            return model[token];
        });
    }

    // Provide global string localization function
    // 3.0: Avoid conflict with String.local data element implemented in c3js
    String.prototype._local = function (model) {    // optional model for interpolation
        if (0 == this.length)
            return this;

        var $term = $.fn.efw$local.data[this];
        if (!$term)
            return _interpolate(this, model);  // no match.

        // failsafe for late-initialization
        if (!OPTS.lang && $('body').is('.l_2'))
            OPTS.lang = 'ES';

        // return localized version if exists
        var $val = $term[OPTS.lang];
        if ($val)
            return _interpolate($val, model);

        if (OPTS.lang != $.fn.efw$local.defaults.lang) {
            // return default language version otherwise.
            $val = $term[$.fn.efw$local.defaults.lang];
            if ($val)
                return _interpolate($val, model);
        }
            
        return this;    // at least do no harm.
    };

    //messages: {
    //        required: "This field is required.",
    //        remote: "Please fix this field.",
    //        email: "Please enter a valid email address.",
    //        url: "Please enter a valid URL.",
    //        date: "Please enter a valid date.",
    //        dateISO: "Please enter a valid date (ISO).",
    //        number: "Please enter a valid number.",
    //        digits: "Please enter only digits.",
    //        creditcard: "Please enter a valid credit card number.",
    //        equalTo: "Please enter the same value again.",
    //        accept: "Please enter a value with a valid extension.",
    //        maxlength: $.validator.format("Please enter no more than {0} characters."),
    //        minlength: $.validator.format("Please enter at least {0} characters."),
    //        rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
    //        range: $.validator.format("Please enter a value between {0} and {1}."),
    //        max: $.validator.format("Please enter a value less than or equal to {0}."),
    //        min: $.validator.format("Please enter a value greater than or equal to {0}.")
    //}
    function _LocalizeJqueryValidation()
    {
        // no-op if jQuery validator is not installed.
        if (!jQuery || !jQuery.validator) {
            return;
        }

        var $messages = {};
        // loop over localization data
        $.each($.fn.efw$local.data, function (k, v) {
            if (k.slice(0, VALIDATE_KEY.length) == VALIDATE_KEY) 
            {
                var val = v[OPTS.lang];
                // may require function returned by $.validator.format
                if (val.indexOf(FUNCTION_TAG) > 0)
                    val = eval(val);

                $messages[k.substring(VALIDATE_KEY.length)] = val;
            }
        });

        $.extend(jQuery.validator.messages, $messages)
    }

    // DB101 localization plugin
    // Initialization function. Must be called once.
    $.fn.efw$local = function (options) {
        OPTS = $.extend({}, $.fn.efw$local.defaults, options);

        // Localize all jquery validation messages
        _LocalizeJqueryValidation();

        return this.each(function () {
            // localize any text contents referenced
            $(this).text($(this).text()._local());
        });
    };

    $.fn.efw$local.data = {}; // extended by efw.local.data.js

    // global defaults.
    $.fn.efw$local.defaults = { lang: "EN" };

    $.fn.efw$local.locale = function () {
        switch (OPTS.lang) {
            case "EN":
                return "en-US";
            case "ES":
                return "es-MX";
        }

        return "en-US";
    };

    $.fn.efw$local.lang = function () {
        return (OPTS.lang ? OPTS.lang.toLowerCase() : 'en');
    };
}(jQuery));