// trackEvents plugin
(function ($) {
    $.fn.trackEvents = function(options) {
        var $opts = jQuery.extend({}, jQuery.fn.trackEvents.defaults, options);

        return this.each(function() {
            var $this = $(this);

            var $label = ($opts.label instanceof Function ? $opts.label($this) : $opts.label.toString());
            //console.log('trackEvents label: ' + $label);
            $this.off('click.trackEvents').on('click.trackEvents', function (/* event */) {
                $.fn.trackEvents.send($opts.category, $opts.action, $label, $opts.value, $opts.nonInteraction);
                //window._gaq && window._gaq.push(['_trackEvent', $opts.category, $opts.action, $label, $opts.value, $opts.nonInteraction]);
                return true;    // allow other click handlers
            });
        });
    };

    // global function
    $.fn.trackEvents.send = function (category, action, label, value, nonInteraction) {
        if (window.gtag) {
            // there's a GA4 or UA tracking property present. Note: 'event_category', 'event_label' and 'value' must be registered in a GA4 property.
            let payload = { 'event_category': category };
            if (label) payload['event_label'] = label;
            if (value && -1 !== value) payload['value'] = value;
            console.log('sending event ' + action + '({' + JSON.stringify(payload) + '})');
            window.gtag('event', action, payload);
        } else if (window._gaq) {
            // Google Analytics Classic [deprecated]
            window._gaq.push(['_trackEvent', category, action, label, value, nonInteraction]);
        }
    };

    $.fn.trackEvents.defaults = {
        category: "Downloads",
        action: "PDF",
        label: function(o) { return o.attr('href'); },
        value: -1,
        nonInteraction: true
    };

    // standard trackEvents binding plugin.
    $.fn.trackEvents$standard = function () {
        return this.each(function () {
            $(this).find('a.styleOffsiteLink, a.styleSiteLink, a.offsite-link').trackEvents({ category: "Clicks", action: "Offsite" });
            $(this).find('a.styleGlossaryLink').trackEvents({ category: "Clicks", action: "Glossary", label: function (o) { var $a = o.attr('href').match(/.*\?item-id=(.+)$/); if ($a && $a.length > 0) return o.prop('rel') + " [" + $a[1] + "]"; return ""; } });
            $(this).find('a.glossary-link').trackEvents({ category: "Clicks", action: "Glossary", label: function (o) { return o.prop('rel') || ''; } });
            $(this).find('a.styleDocumentPDF').trackEvents({ category: "Downloads", action: "PDF" });
            $(this).find('a.styleDocumentDOC').trackEvents({ category: "Downloads", action: "DOC" });
            $(this).find('a.styleDocumentXLS').trackEvents({ category: "Downloads", action: "XLS" });
            $(this).find('a.styleDocumentPPT').trackEvents({ category: "Downloads", action: "PPT" });
            $(this).find('a.styleDocumentMovie').trackEvents({ category: "Clicks", action: "Movie" });
            $(this).find('a.styleMailLink').trackEvents({ category: "Clicks", action: "Mail" });
            $(this).find('a.blurb_link, a#s_launch').trackEvents({ category: "Clicks", action: "Survey" });
            $(this).find("a[class*='popup']").trackEvents({ category: "Clicks", action: "Movie" });
            $(this).find('a.popTool').trackEvents({ category: "Clicks", action: "Tool.Popup", label: function (o) { return o.prop('rel'); } });
            $(this).find('a.calc-toggle-button.grow').trackEvents({ category: "Clicks", action: "Tool.TryIt", label: function (o) { return o.closest('.styleCalcInsertTryIt').attr('name') || ''; } });
        });
    };

    $('document').ready(function () {
    // do this late.
        $.gevent.subscribe($('body'), 'ready-efw', function () {
            $('body').trackEvents$standard();
        });
    });
})(jQuery);

