﻿/* global global, require */
/* eslint-disable no-unused-vars */
window.$ = global.jQuery = require('jquery');
const gevent = require('jquery.event.gevent'),
    _ = require('lodash'),
    cookie = require('js-cookie'),
    //jqueryValidate = require('jquery-validation'),
    efw$mastodon = require('../../common/js/mastodon-timeline-core'),
    iframeResize = require('../../common/js/iframeResizer'),
    iframeResizeContentWindow = require('../../common/js/iframeResizer.contentWindow'),
    bootstrap = require('bootstrap'),
    //bootstrap4Toggle = require('bootstrap4-toggle'),
    //bootstrapAutocomplete = require('bootstrap-autocomplete'),
    sortable = require('jquery-sortablejs'),
    //calculation = require('../../common/js/jquery.calculation'),
    efw$local = require('../../common/js/efw.local.3.1'),
    efw$localdata = require('../../common/js/temp/efw.local.data'),
    efw$trackEvents = require('../../common/js/efw.trackEvents'),
    //efw$broadcast = require('../../common/js/efw.broadcast'),    // efw$broadcast obsolete
    //efw$ping = require('../../common/js/efw.ping'),
    efw$dialog = require('../../common/js/efw.dialog.3.0'),
    efw$formvalid = require('../../common/js/efw.formvalid'),
    efw$feedback = require('../../common/js/efw.feedback'),
    //efw$moveme = require('../../common/js/efw.moveme'),
    ////efw$fbShare = require('../../common/js/efw.fb.share'),
    //efw$glossary = require('../../common/js/efw.glossary.js'),
    efw$gpop = require('../../common/js/efw.gpop.3.0'),
    //efw$hub = require('../../common/js/efw.hub'),
    //efw$toolCollapse = require('../../common/js/efw.toolCollapse'),
    //efw$helpAccordion = require('../../common/js/efw.helpAccordion'),
    //efw$popTool = require('../../common/js/efw.popTool'),
    efw$links = require('../../common/js/efw.links'),
    efw$logon = require('../../common/js/efw.logon.3.0'),
    efw$sister = require('../../common/js/efw.sister'),
    sharrre = require('../../common/js/jquery.sharrre'),
    efw$lirollup = require('../../common/js/efw.lirollup'),
    //efw$growBox = require('../../common/js/efw.growBox'),
    efw$mobile = require('../../common/js/efw.mobile'),
    //efw$twittertimeline = require('../../common/js/efw.twittertimeline'),
    efw$youtubePlaylist = require('../../common/js/efw.youtube.playlist'),
    //efw$finding = require('../../common/js/efw.finding.3.0'),
    //efw$progressRing = require('../../common/js/efw.progressRing'),
    //bp101 = require('../../common/js/bp101.3.0'),
    efw$toolsSupport = require('../../common/js/efw.toolsSupport'),
    hostInterface = require('../../common/js/hostInterface'),
    efw$datapage = require('../../common/js/efw.datapage'),
    hb101$default = require('./default');
    //efw$sharePop = require('../../common/js/efw.sharePop.3.0'),

window.Cookie = cookie;

jQuery(document).ready(function () {
    // publish global initialization event.
    console.log('master: ready');
    _.defer(function () {
        console.log('master: publish');
        jQuery.gevent.publish('init-efw');
    });
});