﻿// Implmentation of DB101 feedback form.
// Teamwork supports Markdown language insertions.
(function ($) {
    // uses this utility function later.
    if (!String.prototype.hashCode) {
        String.prototype.hashCode = function () {
            var hash = 0;
            for (var i = 0; i < this.length; i++) {
                var code = this.charCodeAt(i);
                hash = ((hash << 5) - hash) + code;
                hash = hash & hash; // Convert to 32bit integer
            }
            return hash;
        }
    }

    window._closeModal = function () {
        // hides all dialogs in this domain.
        $('.modal').modal('hide');
    }

    window._showResult = function (model) {
        if ($.gevent) {
            $('.modal').modal('hide');
            $.gevent.publish('efw.result', model); // { title: 'My Title', message: 'My message', timeout: 1000 }
        }
    }

    var $findField = function (form, field) {
        return form.find('[name=' + field + ']');
    },
    _fieldLine = function (form, field, prompt, checkVal) {
        var s = '* ' + prompt + ': ' + $findField(form, field).val() + '\n';
        if (checkVal ? !!$findField(form, field).val() : true)
            return s;
        else
            return '';
    },
        _buildDescription = function (form, site, mode) {
            switch (mode) {
                case 'calculator':
                    return _buildDescriptionCalculator(form, site);
                case 'melp':
                    return _buildDescriptionMelp(form, site);
                default:
                    return _buildDescriptionNormal(form, site);
            }
    }, 
    _buildDescriptionCalculator = function (form, site) {
        var s = '* Site: ' + site + '\n';
        s += _fieldLine(form, 'tbEmail', 'From');
        s += _fieldLine(form, 'tbTime', 'Time');
        s += _fieldLine(form, 'tbServer', 'Server');
        s += _fieldLine(form, 'tbSituation', 'Situation');
        s += _fieldLine(form, 'tbSession', 'Session');
        s += _fieldLine(form, 'tbScreen', 'Screen');
        s += _fieldLine(form, 'tbPlan', 'Plan', true);
        s += _fieldLine(form, 'tbJob', 'Job', true);
        s += _fieldLine(form, 'tbChild', 'Child', true);
        s += '\n' + $findField(form, 'tbComment').val();
        return s;
    }, _buildDescriptionNormal = function (form, site) {
        var s = '* Page: [' + $findField(form, 'tbTitle').val() + '](' + $findField(form, 'tbPath').val() + ')\n';
        s += '* Site: ' + site + '\n',
        s += _fieldLine(form, 'tbEmail', 'From');
        s += '\n' + $findField(form, 'tbComment').val();
        return s;
    },_buildDescriptionMelp = function (form, site) {
        var s = '* Page: [' + $findField(form, 'tbTitle').val() + '](' + $findField(form, 'tbPath').val() + ')\n';
        s += '* Site: ' + site + '\n',
            s += _fieldLine(form, 'tbEmail', 'From');
        s += '\n' + $findField(form, 'tbComment').val();
        return s;
    };

    $.fn.efw$feedback = function (options) {
        const salt = "porkbelly";
        var $opts = jQuery.extend({}, jQuery.fn.efw$feedback.defaults, options);

        return this.each(function () {
            let $this = $(this),
                $form = $this.find('form'), // not reliably found later.
                $submit = $this.find('[type=submit]'),
                $captcha = $this.find('.grecaptcha'),
                captchaId = $captcha.data('recaptcha-id'),
                config = $this.find('[name=tbConfig]').val();

            const _isReCaptcha = function () {
                return (!!window._rcv2 && $captcha.length > 0 && !!window.grecaptcha);
            }

            // prep the form for validation
            $this.on('dlg.initialized', function () {
                // the form element may not be present early on edit sites
                let $localform = $(this).find('form');
                $localform.logon$formvalid();
            });

            // anti-bot behavior.
            $this.on('show.bs.modal', function () {
                $submit.prop('disabled', true);
                setTimeout(function () { $submit.prop('disabled', false); }, 5000);

                let $localform = $(this).find('form'),    // $form is a fragile variable.
                // ...grab ID and config off the $form data
                    $localcaptcha = $localform.find('.grecaptcha'),
                    fCaptchaId = $localcaptcha.data('recaptcha-id');

                // is reCAPTCHA installed?
                if (_isReCaptcha()) {
                    window.grecaptcha.reset(fCaptchaId);
                }
            });

            $submit.on('click', function (e) {
                e.preventDefault();

                // perform form validation
                let $localform = $(e.target).closest('form'),
                // ...grab ID and config off the $form data
                    $localcaptcha = $localform.find('.grecaptcha'),
                    fCaptchaId = $localcaptcha.data('recaptcha-id'),
                    fConfig = $localform.find('[name=tbConfig]').val();

                if (!$localform.logon$formvalid('check'))
                    return;

                // validate reCAPTCHA challenge taken (not actual value)
                if (!_isReCaptcha()) {
                    alert('A configuration error has occurred. Please reload the window and try again.');
                    return;
                }

                if (!window.grecaptcha.getResponse(fCaptchaId)) {
                    alert('validate.captcha'._local());
                    return;
                }

                $submit.prop('disabled', true);
                var taskList = $opts.taskList || $findField($this, 'tbTask').val(),
                    siteName = $opts.siteName || $findField($this, 'tbSite').val(),
                    subj = $findField($this, 'tbSubject').val() || $opts.subjectDefault + ' ' + siteName,
                    validationToken = window.grecaptcha.getResponse(fCaptchaId),   // captchaId is often zero, so don't test !captchaId
                    validationType = 'c',   // type 'x' is obsolete
                    $data = {
                        'todo-item': {
                            content: subj,
                            description: '# ' + subj + '\n' + _buildDescription($this, siteName, $opts.mode),
                            tags: $opts.tags                        
                        },
                        validationToken: validationToken.toString(),
                        validationType: validationType
                    };

                $.ajax({
                    type: "POST",
                    // add config as query string, if specified
                    url: '/tw/tasklists/' + taskList + '/tasks.json' + (fConfig ? '?config=' + fConfig : ''),
                    data: JSON.stringify($data),
                    processData: false,
                    contentType: 'application/json; charset=UTF-8',
                    success: function () {
                        // we're down in the iframe. At this point we don't have direct access to $(this).closest('.modal') because it's in the parent.
                        var model = { title: 'general.success.title', message: 'general.feedback.thanks', timeout: 5000 };
                        if (window.parent)
                            window.parent._showResult(model); //_closeModal();
                        else
                            window._showResult(model);
                    }, error: function (xhr) {
                        console.log('efw.feedback error: ' + xhr.responseText);
                        alert('An error occurred.');
                    }
                });
            });
        });
    };

    $.fn.efw$feedback.defaults = {
        subjectDefault: 'Field Report from',
        tags: 'Triage',
        mode: 'normal'
    };

    // activate globally.
    $(document).ready(function () {
        $('.comment-form-3').efw$feedback();
    });
})(jQuery);