﻿// depends-on: Bootstrap mobile
(function ($) {
    // Eightfold Way mobile menu plugin
    var globalHandler;

    $.fn.efw$mobileDropdown = function (options) {
        var opts = jQuery.extend({}, $.fn.efw$mobileDropdown.defaults, options);

        // handle clicks outside.
        if (!globalHandler) {
            $(document).on('click', function (e) {
                if (!$(e.target).closest('.mobile-dropdown-part').length) {
                    // click outside a menu
                    _clearMenus();
                }
            });
            globalHandler = true;
        }
        var _clearMenus = function () {
            $('.mobile-dropdown-menu').removeClass('active');
            $('.mobile-dropdown-toggle').removeClass('show').prop('aria-expanded', 'false');
        };

        return this.each(function () {
            var $this = $(this),
                $toggle = $(this).find('.mobile-dropdown-toggle'),
                $body = $(this).find('.mobile-dropdown-menu'),
                width = window.innerWidth;

            // do nothing if we're in edit-mode.
            if ($this.closest('.edit-mode').length > 0)
                return;

            // identify all parts of the menu
            $this.addClass('mobile-dropdown-part');
            $toggle.addClass('mobile-dropdown-part');
            $body.addClass('mobile-dropdown-part');

            // When window resizes, clear all menus.
            $(window).resize(function () {
                // problem: Sometimes on mobile devices, the resize event gets triggered when soft keyboard is shown.
                // let's only close menu if there's an actual change in width.
                var newWidth = window.innerWidth;

                if (newWidth != width) {
                    _clearMenus();
                    width = newWidth;
                }
            });

            // reparent the body to the reference object.
            if (opts.reference === 'parent') {
            } else if ((typeof opts.reference) === 'object') {
                $(opts.reference).append($body);
            }

            // ensure that toggle has proper ARIA settings.
            $toggle.attr('aria-haspopup', 'true').attr('aria-expanded', 'false');

            // ensure that the body can gain focus.
            $body.prop('tabindex', '-1');

            // bind the toggle to the body and vice-versa.
            $body.data('toggle', $toggle);
            $toggle.data('body', $body);

            $toggle.on('click', function (e) {
                e.preventDefault();
                var open = $(this).hasClass('show');
                _clearMenus();
                if (!open) {
                    $(this).toggleClass('show')
                        .prop('aria-expanded', 'true');
                    $(this).data('body').toggleClass('active').focus();
                }
                return false;
            });

            // Manage focusout/focusin state.
            // Clicking a control inside the menu generates focusout followed immediately by focusin.
            // Therefore we act on the focusout only if it survives until the event loop.
            // https://stackoverflow.com/questions/152975/how-do-i-detect-a-click-outside-an-element/3028037#3028037
            $body.on({
                //'focusout': function () {
                //    $(this).data('timer', setTimeout(function () {
                //        $(this).removeClass('active');
                //        $(this).data('toggle').removeClass('show')
                //            .prop('aria-expanded', 'true');
                //    }.bind(this), 0));
                //},
                //'focusin': function () {
                //    clearTimeout($(this).data('timer'));
                //},
                'keydown': function (e) {
                    if (e.which === 27) {   // escape key
                        $(this).removeClass('active');
                        $(this).data('toggle').removeClass('show').prop('aria-expanded', 'true');
                    }
                }
            });
        });
    };

    $.fn.efw$mobileDropdown.defaults = {
        reference: 'parent'
    };

    // Eightfold Way mobile initialization
    $(document).ready(function () {
        // make sure mobile menus reference header object so they can go edge-to-edge
        $('.header .mobile-dropdown').efw$mobileDropdown({
            reference: $('.header')[0]
        });
        $('.main .mobile-dropdown').efw$mobileDropdown({});
    });
})(jQuery);
