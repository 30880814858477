﻿// depends-on: Bootstrap
// depends-on: efw.links.js
/* global require */
(function ($) {
    // Eightfold Way glossary popup plugin
    var _ = require('lodash');

    // global close-handler
    let globalHandler;

    // click on a glossary link within a gpop.
    var $gpop_click = function (e) {
        let $this = $(e.currentTarget),
            $body = $this.closest('.popover-body'); 

        // yoink the popover-title, if any
        $body.parent().find('.popover-title').remove();

        // invoke the content function
        let $liner = _.bind($gpop_content, e.currentTarget)();  // triggers ajax immediately
        _.delay(function () {
            $body.html($liner);
            // focus on the content
            $body
                .find('.ititle')
                .attr('tabindex', '0')
                .focus();
        }, 150);   // give it a little time to resolve before displaying, possibly avoiding loading flash
    };
    var $gpop_initial_content = function () {
        let $liner = $('<div></div>').addClass('gpop-liner'),
            $src = $($(this).attr('rel'));
        $liner.html($src.html());
        $liner
            .find('a.glossary-link')
            .gpop$prep()
            .on('click', $gpop_click);
        return $liner;  // ...to the popover plugin
    };
    var $gpop_content = function () {
        // this is bound to the clicked element
        let tmpId = 'tmp-id-' + $.now();
        let $liner = $('<div></div>').attr('id', tmpId).addClass('gpop-liner loading');
        $liner.append('<div class="spinner-border"></div>');
        $.ajax(
            {
                url: '/g/' + $(this).prop('rel') + '.frag'
            }).then(function (content) {
                // prep the content
                $liner
                    .removeClass('loading')
                    .html(content)
                    .efw$links()
                    .find('a.glossary-link')
                    .gpop$prep()
                    .on('click', $gpop_click);
                // focus on the content
                $liner
                    .trackEvents$standard() // bind standard trackEvents links. This has to come after gpop$prep(), which sets prop('rel').
                    .find('.ititle')
                    .attr('tabindex', '0')
                    .focus();
                //// focus on the content for screen readers
                //_.defer(function () {
                //    $liner.find('.ititle').focus();
                //});
            }, function(xhr, status, error){
                $liner.removeClass('loading').html(status + ': ' + error);
            }
        );
        return $liner;
    }; 

    var $gpop_scroll = function (e) {
        if (e.data) {
            let $this = e.data,
                elementTop = $this.offset().top,
                elementBottom = elementTop + $this.outerHeight(),
                viewportTop = $(window).scrollTop(),
                viewportBottom = viewportTop + $(window).height();
            console.log('Im scrolling: %s, %s, %s, %s', elementTop, elementBottom, viewportTop, viewportBottom);
            if (elementBottom < viewportTop || elementTop > viewportBottom)
                $this.popover('hide');
        }
    };

    // brief plugin to condition glossary links.
    $.fn.gpop$prep = function () {
        return this.each(function () {
            let $this = $(this),
                href = $this.prop('href'),
                parts = href.split('#_q'),
                hash = (parts.length > 0 ? parts[1] : '');

            $this.attr('tabindex', '0')
                .prop('rel', hash)
                .on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                });
        });
    };

    // gpop plugin.
    $.fn.gpop = function (options) {
        var opts = $.extend({}, $.fn.gpop.defaults, $('body').is('.l_2') ? $.fn.gpop.defaults.es : {}, options),
            onGlossaryPage = (/glossary.htm/).test(window.location),
            useInitialContent = opts.useInitialContent,
            template = '<div class="popover gpop3" role="tooltip"><button type="button" class="close" data-dismiss="popover" aria-label="' + opts.closePrompt + '"><span aria-hidden="true">&times;</span></button>'
                + (useInitialContent ? '<div class="popover-title">' + opts.helpPrompt + '</div>' : '')
                + '<div class="popover-body" ></div>'
                + (onGlossaryPage || useInitialContent ? '' : '<div class="gpop3-buttons"><a href="/glossary.htm" class="btn-go"><span class="text">' + opts.glossaryPrompt + '</span></a></div>')
                + '</div>';

        // handle clicks outside, once.
        if (!globalHandler) {
            $(document).on('keydown', function (e) {
                if (e.which === 27) {   // escape key
                    $('.modal').modal('hide');
                    $('.popover').popover('hide');
                }
            });
            $(document).on('click', '[data-dismiss="popover"]', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('.popover').popover('hide');
            });
            $(document).on('show.bs.popover', function (e) {
                $('.popover').not($(e.target)).popover('hide');
                // strategy: stash the activating element so we can restore focus
                $(this).data('launcher', document.activeElement);
            });
            $(document).on('hidden.bs.popover', function (e) {
                // ...restore focus now.
                let launcher = $(this).data('launcher');
                if (launcher) {
                    try {
                        $(launcher).focus();
                    } catch {
                        // never mind.
                    }
                }
            });
            $(window).resize(function () {
                $('.popover').popover('hide');
            });
            $('body').on('click', function (e) {
                if (!$(e.target).closest('.gpop-trigger').length
                    && !$(e.target).closest('.popover-trigger').length
                    && !$(e.target).closest('.popover').length) {
                    // click outside a popover
                    $('.popover').popover('hide');
                }
            });
            globalHandler = true;
        }

        return this.each(function () {
            let $this = $(this),
                $containerGpopParent = $this.closest('.gpopParent'), // plan: on the Estimator monthly income/expense page, there are containers that hide overflow, deadly for gpop.
                $containerNeighborhood = $this.closest('p, div');

            $this.gpop$prep()
                .addClass('gpop-trigger')
                .popover({
                    content: (useInitialContent ? $gpop_initial_content : $gpop_content),
                    html: true,
                    trigger: 'click',
                    display: 'static',
                    placement: opts.placement,
                    fallbackPlacement: ['bottom'],
                    flip: 'bottom',
                    container: $containerGpopParent.length > 0 ? $containerGpopParent[0] : $containerNeighborhood[0], // $this.closest('p, div')[0], // 'body',
                    template: template,
                    sanitize: false,
                    boundary: 'window'
                })
                .on('shown.bs.popover', function () {
                    // attach scroll handler
                    // ...passing the actual popover content block as data
                    $(window).on('scroll.gpop', null, $($this.data('bs.popover').tip), $gpop_scroll);
                })
                .on('hide.bs.popover', function () {
                    $(window).off('scroll.gpop');
                });
        });
    };

    $.fn.gpop.defaults = { placement: 'bottom', closePrompt: 'Close', helpPrompt: 'Help', glossaryPrompt: 'Glossary' };
    $.fn.gpop.defaults.es = { closePrompt: 'Cerrar', helpPrompt: 'Ayuda', glossaryPrompt: 'Glosario' };

    // activate.
    $(document).ready(
        function () {
            $('a.ct').gpop({ placement: 'left', useInitialContent: true });
            $('a[href^="/glossary.htm#"]:not([target])').gpop();
        }
    );

})(jQuery);