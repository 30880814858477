﻿; (function ($) {
    // manage global values
    window.hb101$values = function (options) {
        if ('undefined' === typeof (options))
            return hb101$values.q;
        else
            hb101$values.q = jQuery.extend({}, hb101$values.defaults, options);
    };

    hb101$values.defaults = { breakpoint: 992 };
    hb101$values.q = jQuery.extend({}, hb101$values.defaults);

    // TOC activation plugin
    $.fn.hb101$toc = function (options) {
        var $opts = jQuery.extend({}, jQuery.fn.hb101$toc.defaults, options);

        return this.each(function () {
            var $this = $(this);
            // create the backdrop
            var $backdrop = $('<div class="mbackdrop"></div>');
            $backdrop.addClass($opts.cssClass);
            $backdrop.css({ position: 'absolute', zIndex: $opts.zIndex, backgroundColor: 'white', opacity: 0 });
            $backdrop.hide();
            $this.after($backdrop);

            $.gevent.subscribe($this, 'hb101.resize.mobile', function(model) {
                $($opts.header).on('click.hb101$toc', function (e) {
                    var $body = $($opts.body);
                    var $header = $($opts.header);
                    var $top = $header.position().top;
                    var $offset = $header.offset().top;
                    $backdrop.css({ top: $top + 'px', left: $header.position().left + 'px' });
                    $backdrop.width($header.width());
                    $backdrop.height($(document).height() - $offset);
                    $backdrop.stop().show().fadeTo(500, 0.75);
                    $body.slideDown(500);
                });
                $($opts.close).on('click.hb101$toc', function (e) {
                    $backdrop.stop().fadeTo(500, 0, function () { $backdrop.hide(); });
                    $($opts.body).slideUp(500);
                });
                $($opts.body).hide();
                $($opts.content).show();
            });
            $.gevent.subscribe($this, 'hb101.resize.desktop', function (model) {
                $($opts.header).off('click.hb101$toc');
                $($opts.close).off('click.hb101$toc');
                $backdrop.hide();
                $($opts.content).show();
                $($opts.body).show();
            });
        });
    };

    $.fn.hb101$toc.defaults = { header: '.slot_tocHead', body: '.slot_toc', close: '.slot_toc_head', content: '.slot_toc .menuBody', zIndex: 500, cssClass: 'menu-toc-backdrop' };

    // storytelling menu plugin
    $.fn.hb101$storyMenu = function (options) {
        var $opts = jQuery.extend({}, jQuery.fn.hb101$storyMenu.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $container = $($opts.container);

            $.gevent.subscribe($this, 'hb101.resize.desktop', function (model) {
                // reset items
                _RemoveEllipses($container.find('li'));
                $container.find('li').show();
            });

            $.gevent.subscribe($this, 'hb101.resize.mobile', function (model) {
                // reduce items as needed
                var $containerWidth = $($container).width();

                // reset items
                _RemoveEllipses($container.find('li'));
                $container.find('li').show();

                var $itemWidth = _MeasureItems($container);
                //console.log('storyMenu: ' + $itemWidth + ' in ' + $containerWidth);

                if ($containerWidth >= $itemWidth) {
                    return; // no problem.
                }

                _ScoreItems($container);
                var $scores = [0, 10, 12, 100, 102];

                //$container.hide();

                $.each($scores, function (iscore, val) {
                    var $items = $container.find('li').not('.menuItemPrevious').not('.menuItemNext');
                    //console.log('storyMenu: hiding score ' + val);
                    _RemoveEllipses($items);

                    if ($items.filter(function () { return $(this).prop('score') == val; }).hide().length > 0) {
                        // we did something.
                        _InsertEllipses($items);
                        $itemWidth = _MeasureItems($opts.container);
                        //console.log('storyMenu: shrunk to ' + $itemWidth);
                        if ($containerWidth >= $itemWidth)
                            return false; // all done!
                    }
                });
            });
        });
    };

    var _ScoreItems = function(container)
    {
        var $items = container.find('li').not('.menuItemPrevious').not('.menuItemNext');
        var $before = container.find('li:first').nextUntil('.menuItemHere').not('.menuItemPrevious').not('.menuItemNext');
        var $after = container.find('li.menuItemHere').nextAll().not('.menuItemPrevious').not('.menuItemNext');
        var earlyBonus = $before.length > $after.length;
        var $scores = [];

        $items.prop('score', 0);    // by default
        $items.filter('.menuItemHere').prop('score', 1000);
        $items.filter('li:first').prop('score', 10 + (earlyBonus ? 2 : 0));
        $items.filter('li:last').prop('score', 10 + (earlyBonus ? 0 : 2));
        $items.filter('.menuItemHere').prev().prop('score', 100 + (earlyBonus ? 2 : 0));
        $items.filter('.menuItemHere').next().prop('score', 100 + (earlyBonus ? 0 : 2));
        //$items.each(function(i, e) { console.log('score: ' + $(e).prop('score')) });
    }

    var _MeasureItems = function(sel)
    {
        var $measure = 0;

        $(sel).find('li').not(':hidden').each(function() { $measure += $(this).width(); })

        return $measure;
    }

    var _InsertEllipses = function(items)
    {
        var $before, $after;
        var found = false, gap = false, foundAgain = false;

        items.each(function (i, e) {
            if ($(e).is('.menuItemRemoved'))
                return true;

            if ($(e).is(':hidden'))
            {
                if (!found)
                {
                    $before = $(e);
                }
                found = true;
                if (gap && !foundAgain)
                {
                    $after = $(e);
                    foundAgain = true;
                }
            } else {
                if (found)
                {
                    gap = true;
                }
            }
        });

        if ($before) {
            //console.log('inserting before');
            $before.first().before('<li class="menuItem menuItemRemoved"><span class="menuAnchor">&hellip;</span></li>');
        }

        if ($after) {
            //console.log('inserting after');
            $after.first().before('<li class="menuItem menuItemRemoved"><span class="menuAnchor">&hellip;</span></li>');
        }

        //console.log('how many ellipses? ' + items.first().parent().find('.menuItemRemoved').length);
    }

    var _RemoveEllipses = function(items)
    {
        items.filter('.menuItemRemoved').remove();
    }

    $.fn.hb101$storyMenu.defaults = { container: '#content_menu', hereItem: '.menuItemHere' };

    // accordion menu backdrops
    $.fn.hb101$accordionBackdrop = function (options) {
        var $opts = jQuery.extend({}, jQuery.fn.hb101$accordionBackdrop.defaults, options);

        return this.each(function () {
            var $this = $(this);

            // create the backdrop
            var $gray = $('<div class="mbackdrop"></div>');
            $gray.addClass($opts.cssClass);
            $gray.css({ position: 'absolute', zIndex: $opts.zIndex, backgroundColor: 'white', opacity: 0 });
            $gray.hide();
            $this.after($gray);

            // set up accordion events
            $this.on('accordionbeforeactivate', function (e, ui) {
                var $backdrop = $(this).siblings('.mbackdrop').eq(0);
                if (0 == ui.newPanel.length)    // collapsing
                {
                    $backdrop.stop().fadeTo(500, 0, function () { $(this).hide(); });
                } else {
                    var $top = ui.newHeader.position().top + ui.newHeader.height();
                    var $offset = ui.newHeader.offset().top + ui.newHeader.height();
                    $backdrop.css({ top: $top + 'px', left: ui.newPanel.position().left + 'px' });
                    $backdrop.width(ui.newPanel.parent().width());
                    $backdrop.height($(document).height() - $offset);
                    $backdrop.stop().show().fadeTo(500, 0.75);
                }
            });

            // collapse everybody when the window resizes
            $(window).on('resize', null, { accordion: $this }, function (e) {
                e.data.accordion.accordion('option', 'active', false);
            });
        });
    };

    $.fn.hb101$accordionBackdrop.defaults = { zIndex: 1500, cssClass: 'menu-mobile-backdrop' };

    $.fn.hb101$accordionBackdrop = function (options) {
        var $opts = jQuery.extend({}, jQuery.fn.hb101$accordionBackdrop.defaults, options);

        return this.each(function () {
            var $this = $(this);

            // create the backdrop
            var $gray = $('<div class="mbackdrop"></div>');
            $gray.addClass($opts.cssClass);
            $gray.css({ position: 'absolute', zIndex: $opts.zIndex, backgroundColor: 'white', opacity: 0 });
            $gray.hide();
            $this.after($gray);

            // set up accordion events
            $this.on('accordionbeforeactivate', function (e, ui) {
                var $backdrop = $(this).siblings('.mbackdrop').eq(0);
                if (0 == ui.newPanel.length)    // collapsing
                {
                    $backdrop.stop().fadeTo(500, 0, function () { $(this).hide(); });
                } else {
                    var $top = ui.newHeader.position().top + ui.newHeader.height();
                    var $offset = ui.newHeader.offset().top + ui.newHeader.height();
                    $backdrop.css({ top: $top + 'px', left: ui.newPanel.position().left + 'px' });
                    $backdrop.width(ui.newPanel.parent().width());
                    $backdrop.height($(document).height() - $offset);
                    $backdrop.stop().show().fadeTo(500, 0.75);
                }
            });

            // collapse everybody when the window resizes
            $(window).on('resize', null, { accordion: $this }, function (e) {
                e.data.accordion.accordion('option', 'active', false);
            });
        });
    };

    // Global resize events
    var $lastWidth = -1;
    var $resizing = false;

    $(document).ready(function () {
        $(window).on('resize', function (e) {
            if ($resizing)
                return; // don't fire recursively

            var $width = $(window).width();
            var $height = $(window).height();

            $lastWidth = $width;
            $resizing = true;

            if ($(window).width() <= hb101$values().breakpoint) {
                $.gevent.publish('hb101.resize.mobile', [{ width: $width, height: $height }]);
            } else {
                $.gevent.publish('hb101.resize.desktop', [{ width: $width, height: $height }]);
            }

            $resizing = false;

        });

        // $(window).on('load') is much too slow. This global event happens at the end of $(document).ready.
        $.gevent.subscribe($(window), 'init-efw', function () {
            console.log('Receiving init-efw');
            $(window).trigger('resize');
        });
    });
})(jQuery);