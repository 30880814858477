﻿; (function ($) {
    $.fn.lirollup = function (options) {
        var $opts = jQuery.extend({}, jQuery.fn.lirollup.defaults, options);

        return this.each(function () {
            var $this = $(this);

            // find all li children of this ul, regardless of parent
            var $lis = $this.find('li:not(.labelOnly)');
            var $rollups = $this.find("li:not(.labelOnly):gt(" + ($opts.showLimit - 1) + ")");
            var $hidden = true;

            // only do anything if total number of items is too large to show
            if ($lis.length > $opts.showGrace) {
                // hide all items beyond showLimit
                $rollups.hide();

                // add control element to end of list
                var $li = $("<li></li>");
                var $control = $("<a href='javascript:void(0);' class='lirollup_control'></a>");
                $li.append($control);
                $this.append($li);

                function $setState(bHidden) {
                    if (bHidden) {
                        $control.text($opts.hidePrompt).removeClass("show").addClass("hide");
                        $rollups.stop();
                        cascadeShow($rollups, $rollups[0], true);
                        return false;
                    } else {
                        $control.text($opts.showPrompt).removeClass("hide").addClass("show");
                        $rollups.stop();
                        cascadeShow($rollups, $rollups[$rollups.length - 1], false);
                        return true;
                    }
                }

                $control.click(function () {
                    $hidden = $setState($hidden);
                });

                $setState(false);   // hide initially.
            }
        });
    };

    function cascadeShow(a, e, bShow) {
        if (bShow) {
            $(e).slideDown(70, function () {
                var i = $.inArray(e, a) + 1;
                if (i < a.length) {
                    cascadeShow(a, a[i], bShow);
                }
            });
        } else {
            $(e).slideUp(70, function () {
                var i = $.inArray(e, a) - 1;
                if (i >= 0) {
                    cascadeShow(a, a[i], bShow);
                }
            });
        }
    }

    $.fn.lirollup.defaults = {
        showPrompt: "Show more",
        hidePrompt: "Show fewer",
        showLimit: 3,
        showGrace: 5
    };
})(jQuery);