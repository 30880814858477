; (function($) {
    $.fn.sisterMenu = function(options) {
        var $opts = jQuery.extend({}, jQuery.fn.sisterMenu.defaults, options);

        return this.each(function() {
            var $this = $(this);
            $this.find('.menuItem').hover(function (e) {
                $(this).find('.menuItemAbstract').show();
            }, function (e) {
                $(this).find('.menuItemAbstract').hide();
            });
        });
    };

    $.fn.sisterMenu.defaults = {
    };

})(jQuery);

