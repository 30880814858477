﻿/* global require */
// depends-on: jQuery
// Eightfold Way hyperlink styling
(function ($) {
    var Cookies = require('js-cookie');

    $.fn.efw$links = function (/*options*/) {
        //var opts = jQuery.extend({}, $.fn.efw$links.defaults, options);

        return this.each(function () {
            let $this = $(this);

            // popup links.
            $this.find('a[href]').filter('[class^="popup_"],[class*=" popup_"]').each(function () {
                $(this).attr('target', '_blank');
                $(this).on('click', function (e) {
                    e.preventDefault();
                    var pop = window.open($(this).attr('href'), '_blank', 'height=700,width=1000,scrollbars=yes,resizable=yes');
                    setTimeout(function () {
                        pop.focus();
                    }, 1000);
                    return false;
                });
            });

            // Mark offsite links...unless they contain images
            $this.find('a[href^="http://"], a[href^="https://"]').not('[target]').not('.no-offsite').each(function () {
                $(this).prop('target', $(this).attr('href').indexOf('disabilityhubmn.org') > 0 ? '_hub' : '_blank');
                if (0 == $(this).has('img').length)
                    $(this).addClass('offsite-link');
            });

            // mark glossary links
            $this.find('a[href^="/glossary.htm#"]').not('[target]').addClass('glossary-link');

            // handle links to the national home page
            $this.on('click', 'a[href="https://www.db101.org"]', function (e) {
                e.preventDefault();
                // clear site preference cookie
                location = $(this).attr('href') + "?init=1";
            });

            // handle links to other DB101 sites
            $this.on('click', 'a[href$="db101.org"]:not(a[href="https://www.db101.org"])', function (e) {
                // no effect if we're not already DB101
                if (!location.host.includes('db101.org'))
                    return true;    // go about your business.

                e.preventDefault();
                // set site preference cookie and go
                let href = $(this).attr('href'),
                    matches = href.match(/^https:\/\/([^.]+)\.db101\.org$/),
                    key = (matches && matches.length > 1 ? matches[1] : '');
                if (key.length > 0) {
                    Cookies.set('_mhk', key.toLowerCase(), { domain: 'db101.org' });
                }
                location = href;
            });

            // handle links that are supposed to click on other things on the page
            $this.on('click', 'a.click-on', function (e) {
                e.preventDefault();
                e.stopPropagation();
                let $this = $(this),
                    target = $this.attr('rel'),
                    $target = $(target);

                if ($target.length > 0) {
                    $target.first().trigger('click');
                }

                if (window.parent && window.parent !== window) {
                    window.parent.postMessage({ message: 'efw.click-on', target: target }, window.location.origin);
                }
                return false;
            });
            // disable banner link in edit mode
            $('.edit-mode a.no-edit-link').removeAttr('href');
        });
    };

    $.fn.efw$clickOnListener = function () {
        // globally listen for anybody (like an iframe) to tell us to click links.
        const listener = function (e) {
            if (e?.data?.message == 'efw.click-on') {
                console.log('clicking on demand');
                let $target = $(e.data.target);
                if ($target.length > 0) {
                    $target.first().trigger('click');
                }
                return false;
            }
        };

        window.addEventListener('message', listener, false);
    };


    $(document).ready(function () {
        $(document).efw$links();
        //$('.pc_Home4 .slot_content_tail_content p').hide();     // TEMPORARILY: Hide "Follow on Twitter"
        $.fn.efw$clickOnListener();
    });
})(jQuery);
