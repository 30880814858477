﻿// global functions to support tools built with divCalcReplaceFilter.
window.numVal = function (str) {
	var num = Number(str.replace(/\$|,/g, ''));
	return (Number.isNaN(num) ? 0 : num);
}

window.addCommas = function (nStr) {
	nStr += '';
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2');
	}
	return x1 + x2;
}

window.dollarFormat = function (nStr, digits) {
	return '$' + window.addCommas(nStr.toFixed(digits));
}

window.percentFormat = function (nStr, digits) {
	return Number(nStr * 100.0).toFixed(digits) + '%';
}

window.a_Tools = new Object;