﻿/* global require */
/* global YT */
// depends-on: jQuery
// Eightfold Way youtube playlist fetch
(function ($) {
    // APIKey is domain-restricted - can only be called from db101.org, hb101.org, eightfoldway.com.
    // See Google Developer Dashboard, project DB101.
    const APIKey = 'AIzaSyANAxWyib_M79hkOBp7RKN7Ib7fHpBx9_8',
        lang_default = ['en', 'en_US'];

    const langs = {
        'l_1': lang_default,
        'l_2': ['es', 'es_MX']
    };

    const _ = require('lodash'),
        URI = require('urijs'),
        ID = require('./efw.id');

    const _lang_pref = function () {
        //console.log('Preferred language being set');
        try {
            return Object.keys(langs).reduce((o, v) => {
                //console.log(`reduce ${o} ${v} ${$('body').hasClass(v)}`);
                // overwrite accumulator if site is marked as having specific language
                return ($('body').hasClass(v) ? langs[v] : o);
            }, lang_default);   // second argument to reduce = initial value of accumulator
        } catch {
            return lang_default;    // old browsers.
        }
    };

    const _videoUrl = function (id) {
        return 'https://www.youtube.com/embed/' + id + '?rel=0&showinfo=0&autohide=1&enablejsapi=1&origin=' + encodeURIComponent(window.location.origin) + '&cc_lang_pref=' + _lang_pref()[0];
    };

    let players = {};

    const getPlayer = function (id) {
        return players[id];
    };

    const _killOtherVideos = function (id) {
        //console.log('killing videos other than ' + id);
        _.each(players, function (player, key) {
            if (key != id) {
                player.pauseVideo();
            }
        });
    };

    // Key concept: You can't call any functions on the YouTube player until it is ready.
    // Cached instances of the player don't become ready - they are recreated. So don't rely on cache prepared at new YT() time.
    const _onPlayerReady = function (event) {
        //console.log("onPlayerReady");
        if (event && event.target && event.target.getIframe) {
            // now that i'm ready, cache me if you can. event.target is the player instance
            let frame = event.target.getIframe();
            players[frame.id] = event.target;
            // now load the video if specified
            let youtubeId = $(frame).parent().data('youtubeId');
            if (youtubeId) {
                //console.log('loading video: ' + youtubeId);
                event.target.cueVideoById(youtubeId);
            }
        }
    },
        _onStateChange = function (event) {
            //console.log("onStateChange: " + event.data);
            switch (event.data) {
                case YT.PlayerState.PLAYING: {
                    _killOtherVideos(event.target.getIframe().id);
                    if ($.fn.trackEvents) {
                        $.fn.trackEvents.send('Video', 'Play', event.target.getVideoUrl());
                    }
                }
            }
    };

    const _newPlayer = function (id) {
        //console.log('_newPlayer(' + id + ')');
        var l = _lang_pref();
        //console.log('lang_pref: ' + JSON.stringify(l));
        return new YT.Player(id, {
            playerVars: {
                hl: l[1],               // interface language (full culture)
                cc_lang_pref: l[0],     // cc default language (language only)
                cc_load_policy: ('en' === l[0] ? 0 : 1) // captions on by default if EN not current language.
            },
            events: {
                'onReady': _onPlayerReady,
                'onStateChange': _onStateChange
            }
        });
    };

    const _onYouTubeIframeAPIReady = function () {
        //console.log('onYouTubeIframeAPIReady()');
        $('.youtube-player').each(function() {
            //console.log('...found player slot');
            let id = $(this).attr('id');
            // do not cache the player yet
            _newPlayer(id);
        });
    };

    // Global function to load the YouTube iFrame API
    $.efw$youtubeAPILoad = function () {
        // establish global event handler
        window.onYouTubeIframeAPIReady = _onYouTubeIframeAPIReady;
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(tag, firstScript);
    };

    // Plugin to convert <span class="youtube-embed">youtubeVideoId</span> to a responsive embedded player.
    $.fn.efw$youtubeEmbed = function () {
        if (URI().hasQuery('show-params', 'True', 'true', true, 1))
            return;

        return $(this).each(function () {
            let $this = $(this),
                videoId = $this.text().trim();
            $this.text('')
                .data('youtubeId', videoId) // gum under the table
                .append($('<div></div>',
                    {
                        id: ID(),
                        class: 'youtube-player'
                    }
                ))
                //.data('src', _videoUrl(videoId))
                //.append($('<iframe>',
                //    {
                //        id: ID(),
                //        allowfullscreen: '',
                //        src: _videoUrl(videoId),
                //        class: 'youtube-player',
                //        frameborder: '0',
                //        type: 'text/html'
                //    }
                //))
                .removeClass('youtube-embed')
                .addClass('youtube-embedded');
        });
    };

    // Plugin to convert <span class="youtube-playlist-embed">youtubePlaylistId</span> to a responsive embedded playlist.
    $.fn.efw$youtubePlaylistEmbed = function () {
        if (URI().hasQuery('show-params', 'True', 'true', true, 1))
            return;

        return $(this).each(function () {
            let $this = $(this),
                playlistId = $this.text().trim();
            $this.text('')
                .data('playlist', playlistId) // gum under the table
                .removeClass('youtube-playlist-embed')
                .addClass('youtube-playlist');
        });
    };

    $.fn.efw$youtubePlaylist = function (/* options */) {
        //var opts = jQuery.extend({}, $.fn.efw$youtubePlaylist.defaults, options);

        var _formatOne = function (item) {
            if (item && item.snippet && item.snippet.resourceId && item.snippet.thumbnails && item.snippet.thumbnails.default) {
                var $item = $('<div class="vid-item" data-id="' + item.snippet.resourceId.videoId + '"></div>');
                var $thumb = $('<div class="thumb"><img src="' + item.snippet.thumbnails.default.url + '" /></div>');
                var $description = $('<div class="desc"></div>');
                //console.info('_formatOne');
                //console.info(item);
                $description.text(item.snippet.title);
                $item.append($thumb);
                $item.append($description);
                return $item;
            } else {
                // sometimes we get a private video, which is missing some elements.
                return $('<span style="display: none;"></span>');
            }
        };

        var _killPlaylist = function ($parent) {
            $parent.find('.vid-list-container').hide();
            $parent.find('.arrows').hide();
        }

        var _setArrowState = function ($parent) {
            //console.log('_setArrowState');
            // plan: Check to see if the list is scrolled at all. If not turn off left arrow.
            // - Check to see if the scroll is maximized. If so turn off right arrow.
            let $listContainer = $parent.find('.vid-list-container'),
                $rightArrow = $parent.find('.arrow.right'),
                $leftArrow = $parent.find('.arrow.left');

            if (0 === $listContainer.length) {
                $rightArrow.addClass('disabled');
                $leftArrow.addClass('disabled');
            } else {
                let list = $listContainer[0];   // DOM element
                $leftArrow.toggleClass('disabled', list.scrollLeft <= 0);
                $rightArrow.toggleClass('disabled', list.scrollLeft + list.clientWidth >= list.scrollWidth);
            }

            //console.log('list.scrollLeft: ' + $listContainer[0].scrollLeft + '; scrollWidth: ' + $listContainer[0].scrollWidth + '; clientWidth: ' + $listContainer[0].clientWidth);
        };

        var _getPlaylist = function (playlistId, $playlist, $holder) {
            $.ajax({
                type: 'GET',
                dataType: 'json',
                url: 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=30&playlistId=' + playlistId + '&key=' + APIKey, 
                success: function (data /*, textStatus, XMLHttpRequest*/) {
                    var i;
                    //console.log(data);
                    if (1 == data.items.length) {
                        _killPlaylist($playlist.closest('.youtube-playlist')); // one-video playlist!
                    }

                    for (i in data.items) {
                        if (0 == i) {
                            // queue up first item
                            let videoId = data.items[i].snippet.resourceId.videoId;
                            $holder.data('youtubeId', videoId);
                            //console.log('playlist fetched. Queuing ' + data.items[i].snippet.resourceId.videoId);
                            // but is the player ready yet?
                            let id = $holder.find('.youtube-player').attr('id'),
                                player = getPlayer(id);
                            if (player) {
                                //console.log('oh, the player is ready. Go:');
                                player.cueVideoById(videoId);
                            }
                        }
                        $playlist.append(_formatOne(data.items[i]));
                        _setArrowState($playlist.closest('.youtube-playlist'));
                    }
                },
                error: function (req, status, /*error */) {
                    console.log('efw$youtubePlaylist: error: ' + status);
                }
            });
        };

        return this.each(function () {
            let $this = $(this);

            let $container = $('<div class="vid-container"><div id="' + ID() + '" class="vid-frame youtube-player"></div></div>');
            $this.append($container);
            let $playlist = $('<div class="vid-list-container"></div>');

            _getPlaylist($this.data('playlist'), $playlist, $container);

            $this.append($playlist);

            $this.append('<div class="arrows"><div class="arrow left"><img src="/master_images/3/wedge-left.svg" /></div><div class="arrow right"><img src="/master_images/3/wedge-right.svg" /></div></div>');
            
            // click handler.
            $this.on('click', '.vid-item', function (e) {
                e.preventDefault();
                e.stopPropagation();
                let $parent = $(e.target).closest('.youtube-playlist'),
                    player = getPlayer($parent.find('.vid-frame').attr('id'));

                if (player) {
                    player.loadVideoById($(e.target).closest('.vid-item').data('id'));
                }
            });

            // arrows
            let animateOps = {
                duration: 750,
                complete: function () {
                    _setArrowState($this);
                }
            };

            $this.on('click', '.arrow.right', function (e) {
                e.preventDefault();
                let $parent = $(e.target).closest('.youtube-playlist');
                $parent.find('.vid-list-container').stop().animate({
                    scrollLeft: "+=336"
                }, animateOps);
                _.delay(_setArrowState, 100, $this);
            });
            $this.on('click', '.arrow.left', function (e) {
                e.preventDefault();
                let $parent = $(e.target).closest('.youtube-playlist');
                $parent.find('.vid-list-container').stop().animate({
                    scrollLeft: "-=336"
                }, animateOps);
                _.delay(_setArrowState, 100, $this);
            });

            // reset the arrow state when we resize
            $(window).on('resize', function () {
                _setArrowState($this);
            });
        });
    };

    $(document).ready(function () {
        $('.youtube-embed').efw$youtubeEmbed();
        $('.youtube-playlist-embed').efw$youtubePlaylistEmbed();
        $('.youtube-playlist').efw$youtubePlaylist();
        $.efw$youtubeAPILoad();
    });

    //$.fn.efw$youtubePlaylist.defaults = { playlistId: '' };
})(jQuery);

