﻿; (function ($) {
    // efw.datapage plugin
    // given a tab-delimited text file, display as responsive tabular content.
    // expects column headings in first row.
    var KEY_SUBSECTION = 'Subsection';

    // IE polyfill
    if (!String.prototype.startsWith) {
        Object.defineProperty(String.prototype, 'startsWith', {
            value: function (search, rawPos) {
                var pos = rawPos > 0 ? rawPos | 0 : 0;
                return this.substring(pos, pos + search.length) === search;
            }
        });
    }

    $.fn.efw$datapage = function (options) {
        var _prepContent = function (instr) {
            var outstr = instr || '';
            outstr = outstr.trim();
            if (outstr.length < 2)
                return outstr;

            if (outstr.startsWith('"')) {
                outstr = outstr.substring(1, outstr.length - 1);
                outstr = outstr.replace(/\"\"/g, '"'); // escaped quotes.
            }

            return outstr;
        };
        var _makeCell = function (i, content) {
            return $('<div></div>')
                .addClass($opts.class + '-col')
                .addClass($opts.class + '-col-' + i)
                .append($('<div></div>')
                    .addClass('content')
                    .html(content));
        };

        var _makeClass = function (s) {
            // convert to valid class identifier
            return s.toLowerCase().replace(/([^a-zA-Z0-9\-\_])/g, '-');
        };

        var $opts = jQuery.extend({}, jQuery.fn.efw$datapage.defaults, options);

        return this.each(function () {
            var $this = $(this);

            var $root = $('<div></div>').addClass($opts.class);
            if ($opts.extraClass) {
                $root.addClass($opts.extraClass);
            }

            if (!$opts.infile) {
                console.error('efw$datapage: Missing required option "infile".');
                return;
            }

            // Read the input file.
            $.ajax({
                type: 'GET',
                encoding: "UTF-8",
                dataType: "text",
                contentType: "text/plain; charset=UTF-8",
                url: $opts.infile
            })
                .done(function (data) {
                    var trows = data.split('\n'),
                        trow0,
                        $row = $('<div></div>').addClass($opts.class + '-row ').addClass($opts.class + '-header');

            // read off the first row and parse as column headers.
                    if (0 === trows.length)
                        return;

                    trow0 = trows[0];
                    trows = trows.slice(1);
                    if (0 === trows.length)
                        return;

                    var theaders = trow0.split('\t'),
                        headers = [],
                        rows = [];

                    // parse and save the header text
                    $.each(theaders, function (i, item) {
                        var t = item.trim();
                        headers[i] = (KEY_SUBSECTION === t ? { subsection: true } : { content: _prepContent(t) });
                        if (1 === i)
                            headers[i].title = true;    // assuming the title column is after subsection column
                        if (headers[i].content)
                            headers[i].class = _makeClass(headers[i].content);
                    });

                    // now parse and save the rest of the data.
                    $.each(trows, function (ir, trow) {
                        var tcols = trow.split('\t');
                        if (tcols.length > 1) {
                            rows[ir] = { columns: [] };
                            $.each(tcols, function (ic, tcol) {
                                if (headers[ic].subsection && tcol) {
                                    rows[ir].subsection = true;  // non-empty entry in subsection column.
                                } else {
                                    rows[ir].columns[ic] = _prepContent(tcol);
                                }
                            });
                        }
                    });

            //// Desktop layout.
                    let $desktop = $('<div></div>').addClass('desktop'),
                        $header = $('<div></div>').addClass($opts.class + '-header-group'), // e.g. thead
                        $body = $('<div></div>').addClass($opts.class + '-body-group'); // e.g. tbody

                    $.each(headers, function (i, h) {
                        if (!h.subsection)
                            $row.append(_makeCell(i, h.content).addClass(h.class ? h.class : ''));
                    });

                    $header.append($row);
                    $desktop.append($header);

            // Per input file line...
                    $.each(rows, function (ir, row) {
            // build a row...
                        $row = $('<div></div>')
                            .addClass($opts.class + '-row');
                        if (row.subsection)
                            $row.addClass('subsection');

            // of cells.
                        $.each(row.columns, function (ic, col) {
                            if (!headers[ic].subsection) {
                                var $cell = _makeCell(ic, col);
                                if (headers[ic].title)
                                    $cell.addClass($opts.class + '-title');
                                if (headers[ic].class)
                                    $cell.addClass(headers[ic].class);
                                if (col.length < 4) {
                                    $cell.addClass(_makeClass(col));
                                }
                                $row.append($cell);
                            }
                        });

                        $body.append($row);
                    });

                    $desktop.append($body);

            //// Mobile layout.
                    let $mobile = $('<div></div>').addClass('mobile');

            // Per data row...
                    $.each(rows, function (ir, row) {
                        // build a block...
                        $row = $('<div></div>').addClass($opts.class + '-block');
                        if (row.subsection)
                            $row.addClass('subsection');

                        // of items.
                        $.each(row.columns, function (ic, col) {
                            if (!headers[ic].subsection) {
                                if (headers[ic].title) {
                                    // title item
                                    // ...content
                                    $row.append(
                                        $('<h3></h3>')
                                            .addClass($opts.class + '-block-title')
                                            .html(col)
                                    );
                                } else {
                                    // all other items
                                    // ...remove prompt-only items
                                    if (col.length > 0) {
                                        // ...header
                                        $row.append(
                                            $('<div></div>')
                                                .addClass($opts.class + '-block-header')
                                                .html(headers[ic].content)
                                        );
                                        // ...content
                                        $row.append(
                                            $('<div></div>')
                                                .addClass($opts.class + '-block-content' + (headers[ic].class ? ' ' + headers[ic].class : '') + (col.length < 4 ? ' ' + _makeClass(col) : ''))
                                                .html(col)
                                        );
                                    }
                                }
                            }
                        });

                        $mobile.append($row);
                    });

                    $root.append($desktop);
                    $root.append($mobile);

                    // Fixup parameter references
                    $root.find('span[class~="styleParam"]').each(function () {
                        var $span = $(this),
                            key = $span.text(),
                            $lookup = $('.e-param[data-key="' + key + '"]'),
                            value = ($lookup.length > 0 ? $lookup.first().html() : '');
                        $span.removeClass('styleParam').addClass('param-replaced').html(value);
                    });
                    // 5) Fixup offsite links
                    $root.find('a[href^="http"]').addClass('styleOffsiteLink').attr('target', '_blank');
                    //console.log('Links: ' + $root.find('a[href^="http"]').length);

                    $this.append($root);
                })
                .fail(function (err) {
                    console.err('efw$datapage: ' + err);
                });
        });
    };

    $.fn.efw$datapage.defaults = {
        class: "datapage"
    };
})(jQuery);